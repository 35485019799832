import axios from 'axios';
import Utils from '.';

const $axios = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_VERSION}`,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',

  },
});
/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
  let accessToken = sessionStorage.getItem("authToken");
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }

  return accessToken;
};
export const setAuthorizationToken = (token) => {
  if (token) {
    // Utils.constants.axios.defaults.headers.common[
    //   "accesstoken"
    // ] = `bearer ${token}`;

    Utils.constants.axios.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    // delete Utils.constants.axios.defaults.headers.common["accesstoken"];
  }
};
// export default {
//     mongoBaseUrl: "https://bodyshopdev.appskeeper.in/admin_p373hn/admin/",
// }

const constants = {
  axios: $axios,
  getAccessToken: getAccessToken,
  mongoBaseUrl: "https://bodyshopdev.appskeeper.in/admin_p373hn/admin/",
  SKELETON_LOADING: "skeleton_loading",

}
export default constants;