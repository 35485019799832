import React from "react";
import {
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle } from "@material-ui/icons";
import auth from "../../utils/auth";
import { useHistory } from "react-router-dom";
import request from "../../utils/request";
import CustomAlert from "../../components/customAlert";
import Utils from "../../utils";


const useStyles = makeStyles((theme) => ({
    root: {
        // position: "absolute"
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "fixed",
        top: "64px",
        width: "230px",
    },
    container: {
        margin: 10,
        minHeight: "calc(100vh - 74px)",
        // minHeight: 1000,
        textAlign: "center",
        position: "relative",
        top: "64px",
    },
}));

const HeaderLayout = () => {
    const classes = useStyles();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const [snackbar, SetSnackbar] = React.useState({
        open: false,
        text: null,
        type: "error",
    });


    const closeAlert = () => {
        SetSnackbar({ ...snackbar, text: null, open: false, type: "error" });
    };


    const hanldeClick = (e) => {
        request
            .patch(Utils.endPoints.logout)
            .then((resp) => {
                auth.logout(() => {
                    SetSnackbar({
                        ...snackbar,
                        text: "Logout Successfully",
                        open: true,
                        type: "success",
                    });
                    history.push("/login");
                });
            })
            .catch((err) => {
                SetSnackbar({
                    ...snackbar,
                    text: err.response.data.message,
                    open: true,
                });
            });
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMyProfile = () => {
        history.push("/my-profile");
        handleClose();
    };

    return (
        <>
            <CustomAlert
                open={snackbar.open}
                handleClose={closeAlert}
                type={snackbar.type}
                text={snackbar.text}
            />
            <AppBar position="fixed">
                <Toolbar>
                    {/* <IconButton edge="start" onClick={handleDrawerOpen} className={classes.menuButton} color="inherit" aria-label="menu">
                              <MenuIcon />
                          </IconButton> */}
                    <Typography variant="h6" className={classes.title}>
                        THE BODYSHOP
                    </Typography>
                    {auth && (
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={(e) => handleMyProfile(e)}>
                                    {" "}
                                    My account
                                </MenuItem>
                                <MenuItem onClick={(e) => hanldeClick(e)}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </>
    )
}


export default HeaderLayout