import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import Utils from "../utils";
import ContainerLayout from "../pages/layouts/container";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Suspense falback={"loading"}>
      {
        <ContainerLayout>
          <Route
            {...rest}
            render={(props) => {
              return Utils.auth.isAuthenticated() ?
                <Component {...props} />
                :
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      from: props.location,
                    },
                  }}
                />
            }
            }
          />
        </ContainerLayout>
      }
    </Suspense>

  );
};
