import { ReviewsListing ,  ReviewProductListing} from "../../modals/index";



export const reviewsListReducer = (state = new ReviewsListing(), action) => {
    switch (action.type) {
      case "getreviewsLists":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };



  export const reviewProductListingReducer = (state = new ReviewProductListing(), action) => {
    switch (action.type) {
      case "getProductList":
        return { ...state, ...action.payload };
  
      default:
        return state;
    }
  };
  