import constants from './constant';
import endPoints from './endPoints';
import auth from './auth';
import routes from './routes';
import CommonFunctions from './commonFunction';
import Images from './images';

const Utils = {

  constants,
  endPoints,
  auth,
  routes,
  CommonFunctions,
  Images
};

export default Utils;