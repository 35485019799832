class Auth {
    constructor() {
        this.authenticated = sessionStorage.getItem("authToken") ? true : false;
        this.authToken = sessionStorage.getItem("authToken") ?? null
    }

    login(cb, data) {
        this.authenticated = true;
        for (var k in data) {
            var isCurrentValObject = typeof data[k] === "object";
            if (isCurrentValObject && data[k] !== null) {
                //if property is object then save it as string
                sessionStorage.setItem(k, JSON.stringify((data)[k]));
            } else {
                sessionStorage.setItem(k, (data)[k]);
            }
        }

        this.authToken = data.authToken
        // sessionStorage.setItem("authToken", data.authToken)
        // sessionStorage.setItem("user_detail", data.authToken)
        cb();
    }

    logout(cb) {
        this.authenticated = false;
        this.authToken = null
        sessionStorage.clear()
        cb();
    }

    isAuthenticated() {
        return sessionStorage.getItem("authToken") ? true : false;
    }
    getAuthToken() {
        return this.authToken;
    }
    getUserDetail() {
        return this.userDetail;
    }
}

export default new Auth()