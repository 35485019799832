import './App.css';
import { Skeleton } from '@material-ui/lab';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import CustomRoute from "./routes/customRoute"
import { Provider } from 'react-redux';
import Store from './store';
import { Suspense } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import HeaderLayout from './pages/layouts/header';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#356d60',
      main: '#004236',
      dark: '#001d10',
      contrastText: '#fff',
    },
    secondary: {
      light: '#356d60',
      main: '#004236',
      dark: '#001d10',
      contrastText: '#fff',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
    margin: 0,
    padding: 0
  },
  skeleton: {
    marginTop: theme.spacing(1)
  }
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>

        <div className={classes.root}>
          <Provider store={Store}>
            <Suspense fallback={
              <>
                {
                  [...Array(6).keys()].map((item) => (
                    <div key={item}>
                      <Skeleton className={classes.skeleton} variant="rect" width={250} height={70} />
                    </div>

                  ))
                }
                <HeaderLayout />
              
              </>
            }>
              <CustomRoute />
            </Suspense>
          </Provider>
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
