import { combineReducers } from "redux";
import { offerListingReducer } from "../pages/offerManagement/reducer";
import { consentListingReducer } from "../pages/consent/reducer";
import { corporateGiftReducer } from "../pages/giftCardManagement/reducer";
import { voucherListReducer } from "../pages/voucherManagement/reducer";
import {
  reviewsListReducer,
  reviewProductListingReducer,
} from "../pages/reviewRatingManagement/reducer";
import { ticketListingReducer } from "../pages/ticketManagement/reducer";
import {
  helpReducer,
  helpQuestionReducer,
} from "../pages/helpSupportManagement/reducer";
import { loadingReducer } from "../components/loaderReducer/reducer";

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  loadingReducer,
  reviewsListReducer,
  offerListingReducer,
  consentListingReducer,
  corporateGiftReducer,
  voucherListReducer,
  reviewProductListingReducer,
  ticketListingReducer,
  helpReducer,
  helpQuestionReducer,
});

export default rootReducer;
