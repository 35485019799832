import { Help, QuestionListing } from "../../modals/index";

export const helpReducer = (state = new Help(), action) => {
  switch (action.type) {
    case "getHelpListing":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};



export const helpQuestionReducer = (state = new QuestionListing(), action) => {
  switch (action.type) {
    case "getQuestionListing":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
