import { TicketListing } from "../../modals/index";

export const ticketListingReducer = (state = new TicketListing(), action) => {
  switch (action.type) {
    case "getTicketList":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
