import CardGiftcardSharpIcon from "@material-ui/icons/CardGiftcardSharp";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ForumIcon from "@material-ui/icons/Forum";
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PaymentIcon from "@material-ui/icons/Payment";
import routes from "./routes";

export const SideMenu = [
  // {
  //   title: "Dashboard",
  //   icon: DashboardIcon,
  //   route: routes.DASHBOARD,
  //   selection: "dashboard",
  // },
  // {
  //   title: "Offer Management",
  //   icon: LocalOfferIcon,
  //   route: "/",
  //   selection: "offer",
  // },
  {
    title: "Consent Management",
    icon: ForumIcon,
    route: "/",
    selection: "consent",
  },
  {
    title: "Reviews Management",
    icon: RateReviewIcon,
    route: routes.REVIEW_RATING_LIST,
    selection: "review",
  },
  {
    title: "Voucher Management",
    icon: PaymentIcon,
    route: routes.VOUCHER_LIST,
    selection: "voucher",
  },
  {
    title: "Ticket Management",
    icon: ConfirmationNumberIcon,
    route: routes.TICKET_LIST,
    selection: "ticket",
  },
  // {
  //   title: "Notification Management",
  //   icon: NotificationsActiveIcon,
  //   route: routes.NOTIFICATION_LIST,
  //   selection: "notification",
  // },
  // {
  //   title: "Donation Management",
  //   icon: null,
  //   route: routes.DONATION_LIST,
  //   selection: "donation",
  // },
  
  {
    title: "Help & Support Management",
    icon: ContactSupportIcon,
    route: routes.HELP_SUPPORT_LISTING,
    selection: "help-support",
  },
  {
    title: "Corporate Gift Management",
    icon: CardGiftcardSharpIcon,
    route: routes.GIFT_CARD_LIST,
    selection: "gift-card",
  },
];
