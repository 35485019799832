import { LoadingModal } from "../../modals";
import Utils from "../../utils";

export const loadingReducer = (state = new LoadingModal(), action) => {
  switch (action.type) {
    case Utils.constants.SKELETON_LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
