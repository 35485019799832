import { ConsentListing } from "../../modals/index";

const consentListing = new ConsentListing();
export const consentListingReducer = (state = consentListing, action) => {
  switch (action.type) {
    case "consent.list":
      return { ...state, data: { ...action.payload } };
    default:
      return state;
  }
};
