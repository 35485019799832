import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Drawer,
  List,
  Divider,
  Card,
  CardContent,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory, useLocation } from "react-router-dom";
import { SideMenu } from "../../utils/sideMenu";
import HeaderLayout from "./header";

const useStyles = makeStyles((theme) => ({
  root: {
    // position: "absolute"
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "fixed",
    top: "64px",
    width: "230px",
  },
  container: {
    margin: 10,
    minHeight: "calc(100vh - 74px)",
    // minHeight: 1000,
    textAlign: "center",
    position: "relative",
    top: "64px",
  },
}));

function ContainerLayout({ children }) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (e, route) => {
    history.push(route)
  }

  
  return (
    <>
      <CssBaseline />
      {/* <CircularProgress /> */}

      <div className={classes.root}>
        <HeaderLayout />
        <Grid container>
          <Grid item xs={2}>
            <Drawer
              variant="persistent"
              anchor="left"
              open={true}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <List>
                {SideMenu.map((value, index) => {
                  let Icon = value.icon;
                  return (
                    <React.Fragment key={index}>
                      <ListItem
                        selected={location.pathname.includes(value.selection)}
                        // selected={location.pathname == value.route}
                        button
                        key={index}
                        onClick={(e) => handleClick(e, value.route)}
                      >
                        {Icon ? (
                          <ListItemIcon>
                            <Icon color="primary" />
                          </ListItemIcon>
                        ) : null}
                        <ListItemText primary={value.title} />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </List>
            </Drawer>
          </Grid>
          <Grid item xs={10}>
            <Card className={classes.container}>
              <CardContent>{children}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default ContainerLayout;
