
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const customAlert = (props) => {
    return (
        <Snackbar open={props.open} autoHideDuration={6000} onClose={props.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <MuiAlert onClose={props.handleClose} severity={props.type}>
                {props.text}
            </MuiAlert>
        </Snackbar>
    )
}

export default customAlert