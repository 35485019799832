const API_BASE_URL_S1 = `${process.env.REACT_APP_API_BASE_URL}admin-service/api/`
const API_BASE_URL_S2 = `${process.env.REACT_APP_API_BASE_URL}promotion-service/api/${process.env.REACT_APP_VERSION}`
const API_BASE_URL_S3 = `${process.env.REACT_APP_API_BASE_URL}order-service/api/`
const API_BASE_URL_S4 = `${process.env.REACT_APP_API_BASE_URL}user-service/api/`;



export default {
    /**
     * Module 1
     */
    login: `${API_BASE_URL_S1}admins/login`,
    logout: `${API_BASE_URL_S1}admins/logout`,
    profile: `${API_BASE_URL_S1}admins/profile`,

    /**
     * Module 2
     */
    getOfferList: `${API_BASE_URL_S2}promotions`,
    getOfferDetail: `${API_BASE_URL_S2}promotions/:id`,
    getCouponList: `${API_BASE_URL_S2}promotion-coupons`,
    getConsentList: `${API_BASE_URL_S2}consent`,
    getVoucherlist: `${API_BASE_URL_S2}vouchers`,
    getVoucherDetail : `${API_BASE_URL_S2}voucher/:id`,


    /**
     * Corporate Gifting
     */
    getCorporateGiftList: `${API_BASE_URL_S3}${process.env.REACT_APP_VERSION}corporate-giftcards`,
    getTicketList: `${API_BASE_URL_S3}admins/ticket-list`,
    getTicketDetails: `${API_BASE_URL_S3}admins/ticket/:id`,

    /**
     * Rating Listing
     */
     getreviewsLists: `${API_BASE_URL_S4}admins/reviews-lists`,
     getReviewDetails : `${API_BASE_URL_S4}admins/review/:id`,
     getProductList: `${API_BASE_URL_S4}admins/product-users-reviews-list`,
     getHelpListing : `${API_BASE_URL_S4}admins/help-category-listing`,
     getHelpDetail : `${API_BASE_URL_S4}admins/help-category/:catId`,
     getQuestionListing : `${API_BASE_URL_S4}admins/help-category-questions`,

}