class LoadingModal {
  skeletonLoader= false;
}


class OfferListing { 
  data = [];
}
class CouponListing {
  data = [];
}



class ConsentListing {
  data = [];
}

class CorporateGiftListing {
  data = [];
}

class VoucherList {
  data = [];
}

class VoucherDetail {
  data = [];
}

class ReviewsListing {
  data = [];
}


class ReviewProductListing {
  data = [];
}

class TicketListing {
  data = [];
}

class TicketDetails {
  data = [];
}

class Help {
  data = []
}

class QuestionListing {
  data = [];
}

// export class ReducerModal {
//   helpQuestionReducer = new QuestionListing();
//   helpReducer = new Help();
//   ticketDetailReducer = new TicketDetails();
//   ticketListingReducer = new TicketListing();
//   reviewProductListingReducer = new ReviewProductListing();
//   reviewDetailReducer = new ReviewDetail();
//   reviewsListReducer = new ReviewsListing();
//   voucherDetailReducer = new VoucherDetail();
//   voucherListReducer = new VoucherList();
//   offerListingReducer = new OfferListing();
//   couponListingReducer = new CouponListing();
//   offerDetailReducer = new OfferDetails();
//   consentListing = new ConsentListing();
//   corporateGiftListing = new CorporateGiftListing();
// }



export {
  LoadingModal,
  QuestionListing,
  Help,
  OfferListing,
  CouponListing,
  ConsentListing,
  CorporateGiftListing,
  VoucherList,
  VoucherDetail,
  ReviewsListing,
  ReviewProductListing,
  TicketListing,
  TicketDetails,
};
