import axios from "axios"
import auth from "./auth"


// Set config defaults when creating the instance
const instance = axios.create({
    // baseURL,
    timeout: 100000,
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['language'] = 'en';
instance.defaults.headers.common['offset'] = '-330';

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    if (auth.isAuthenticated()) {
        config.headers.common['Authorization'] = `Bearer ${auth.getAuthToken()}`;
    } else {
        config.headers.common['Authorization'] = `Basic ${process.env.REACT_APP_API_KEY}`

    }
    return config;
});


// Alter defaults after instance has been created

export default instance