import { OfferListing } from "../../modals/index";

export const offerListingReducer = (state = new OfferListing(), action) => {
  switch (action.type) {
    case "offer.list":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
