import { VoucherList } from "../../modals/index";

export const voucherListReducer = (state = new VoucherList(), action) => {
  switch (action.type) {
    case "getVoucherlist":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

