import { CorporateGiftListing } from "../../modals/index";
const corporateGiftListing = new CorporateGiftListing();
export const corporateGiftReducer = (state = corporateGiftListing, action) => {
  switch (action.type) {
    case "getGiftCardlist":
      return { ...state, data: { ...action.payload } };
    default:
      return state;
  }
};
