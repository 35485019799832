import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import RootReducer from "../reducers";

// const enhancer = compose(applyMiddleware(thunk, logger));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
    predicate: () => process.env.REACT_APP_ENV === 'development',
});


/**
 * create new store
 */

// const store = createStore(RootReducer, enhancer);

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk, logger)))

export default store;
