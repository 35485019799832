const routes = {
    DASHBOARD: "/dashboard",
    OFFER_LIST: `/offer-list`,
    OFFER_DETAIL: `/offer-details/:id`,
    MY_PROILE: `/my-profile`,
    CONSENT_LOG: `/consent-logs`,
    REVIEW_RATING_LIST: `/review-rating-list`,
    REVIEW_RATING_DETAIL: `/review-details/:id`,
    VOUCHER_LIST: `/voucher-list`,
    VOUCHER_DETAIL: `/voucher-details/:id`,
    TICKET_LIST: `/ticket-list`,
    TICKET_DETAIL: `/ticket-details/:id`,
    NOTIFICATION_LIST: `/notification-list`,
    NOTIFICATION_DETAIL: `/notification-details/:id`,
    DONATION_LIST: `/donation-list`,
    DONATION_DETAIL: `/donation-details/:id`,
    GIFT_CARD_LIST: `/gift-card-list`,
    GIFT_CARD_DETAIL: `/gift-card-details/:id`,
    HELP_SUPPORT_LISTING: `/help-support-list`,
    HELP_SUPPORT_DETAIL: `/help-support-detail/:id`,
    
  };
  export default routes;
  