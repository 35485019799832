import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ProtectedRoute } from "../components/protectedRoute";
import { lazy, Suspense } from "react";
import Utils from "../utils";

/**
 * Components
 */
const Home = lazy(() => import("../pages/home"));
const Login = lazy(() => import("../pages/auth/login.page"));
const MyProfile = lazy(() => import("../pages/myProfile"));

// const OfferManagement = lazy(() => import("../pages/offerManagement"));

// const OfferDetails = lazy(() =>
//   import("../pages/offerManagement/offerDetails")
// );

const ReviewRatingManagement = lazy(() =>
  import("../pages/reviewRatingManagement")
);
const ReviewRatingDetails = lazy(() =>
  import("../pages/reviewRatingManagement/reviewRatingDetails")
);
const VoucherManagement = lazy(() => import("../pages/voucherManagement"));
const VoucherDetails = lazy(() =>
  import("../pages/voucherManagement/voucherDetails")
);

const TicketManagement = lazy(() => import("../pages/ticketManagement"));
const TicketDetails = lazy(() =>
  import("../pages/ticketManagement/ticketDetails")
);

const NotificationManagement = lazy(() =>
  import("../pages/notificationManagement")
);
const NotificationDetails = lazy(() =>
  import("../pages/notificationManagement/notificationDetails")
);

const DonationManagement = lazy(() => import("../pages/donationManagement"));
const DonationDetails = lazy(() =>
  import("../pages/donationManagement/donationDetails")
);

const GiftCardManagement = lazy(() => import("../pages/giftCardManagement"));

const HelpSupportManagement = lazy(() =>
  import("../pages/helpSupportManagement/questionManagement")
);
const HelpDetail = lazy(() =>
  import("../pages/helpSupportManagement/helpDetail")
);
/**
 * Consent Logs
 */
const ConsentList = lazy(() => import("../pages/consent/consentList"));

export default function CustomRoute() {
  
  return (
    <div>
      <Router>
        <Switch>
          <ProtectedRoute
            exact
            path={Utils.routes.DASHBOARD}
            component={Home}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.MY_PROILE}
            component={MyProfile}
          />

          {/* Offer Managment */}
          {/* <ProtectedRoute exact path="/" component={OfferManagement} /> */}
          {/* <ProtectedRoute
              exact
              path={Utils.routes.OFFER_DETAIL}
              component={OfferDetails}
            /> */}

          {/* Consent Log */}
          <ProtectedRoute
            exact
            path="/"
            component={ConsentList}
          />

          {/* Review Rating Managment */}
          <ProtectedRoute
            exact
            path={Utils.routes.REVIEW_RATING_LIST}
            component={ReviewRatingManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.REVIEW_RATING_DETAIL}
            component={ReviewRatingDetails}
          />
          {/* VoucherManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.VOUCHER_LIST}
            component={VoucherManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.VOUCHER_DETAIL}
            component={VoucherDetails}
          />

          {/* TicketManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.TICKET_LIST}
            component={TicketManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.TICKET_DETAIL}
            component={TicketDetails}
          />

          {/* NotificationManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.NOTIFICATION_LIST}
            component={NotificationManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.NOTIFICATION_DETAIL}
            component={NotificationDetails}
          />

          {/* DonationManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.DONATION_LIST}
            component={DonationManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.DONATION_DETAIL}
            component={DonationDetails}
          />

          {/* GiftCardManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.GIFT_CARD_LIST}
            component={GiftCardManagement}
          />

          {/* Help&SupportManagement */}
          <ProtectedRoute
            exact
            path={Utils.routes.HELP_SUPPORT_LISTING}
            component={HelpSupportManagement}
          />
          <ProtectedRoute
            exact
            path={Utils.routes.HELP_SUPPORT_DETAIL}
            component={HelpDetail}
          />
          <Route
            exact
            path="/login"
            render={(props) => {
              if (!Utils.auth.isAuthenticated()) {
                return <Login />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }
            }}
          />

          <Route
         
            component={(props) => <Suspense fallback={""}>Not Found</Suspense>}
          />
        </Switch>
      </Router>
    </div>
  );
}
